<div class="create-tournament">
  <p class="create-tournament__title">Crear Torneo</p>
  @if (createTeamForm) {
  <form [formGroup]="createTeamForm">
    <ion-item>
      <ion-input
        formControlName="name"
        label-placement="floating"
        label="Nombre del equipo"
        placeholder="Ingrese el nombre del equipo"
      ></ion-input>
    </ion-item>
  </form>
  }
  <div class="footer-btns">
    <ion-button
      color="primary"
      [disabled]="createTeamForm?.invalid"
      (click)="addTeamToTable()"
      >Crear</ion-button
    >
    <ion-button color="medium" (click)="cancel()">Cancelar</ion-button>
  </div>
</div>
