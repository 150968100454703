import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoaderUtils {
  private loadingElement: HTMLIonLoadingElement | undefined = undefined;
  constructor(private loadingCtrl: LoadingController) {}

  /**
   * * Method to show a toast
   *
   * @param message
   */
  public async showLoader(message: string): Promise<void> {
    this.loadingElement = await this.loadingCtrl.create({
      message,
    });
    await this.loadingElement.present();
  }

  /**
   * * Method to hide the loader
   *
   */
  public async hideLoader(): Promise<void> {
    if (this.loadingElement) {
      this.loadingElement.dismiss();
    }
  }
}
