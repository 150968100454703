<div class="settings">
  <div class="settings-header">
    <p>Configuración</p>
  </div>
  <div class="mat-elevation-z8 mt-3">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nombre</th>
        <td mat-cell *matCellDef="let element">{{ element.titulo }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.isActive ? 'Activa' : 'Inactiva' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Acciones</th>
        <td mat-cell *matCellDef="let element">
          <button mat-flat-button class="activate-btn" [disabled]="element.isActive" (click)="updateJornada(element, true)">Activar</button>
          <button mat-flat-button class="deactivate-btn" [disabled]="!element.isActive" (click)="updateJornada(element, true)">Inactivar</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
