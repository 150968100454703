import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { ToastUtils } from '@lnfp/ui';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public currentUser$ = this.currentUserSubject.asObservable();

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private toastUtils: ToastUtils
  ) {
    this.checkAuthenticationState();
  }

  /**
   * * Function to check auth state
   */
  private checkAuthenticationState(): void {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.currentUserSubject.next(true);
      } else {
        this.currentUserSubject.next(false);
      }
    });
  }

  /**
   * * Function to login a user
   *
   * @param email
   * @param password
   */
  public async login(email: string, password: string) {
    if (email.includes('laligahn.com')) {
      this.auth
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          this.router.navigate(['app/primicias'], { replaceUrl: true });
        })
        .catch((error) => {
          if (error.code === 'auth/wrong-password') {
            this.toastUtils.showToast(
              'Contraseña incorrecta, intenta de nuevo',
              'danger'
            );
          } else if (error.code === 'auth/user-not-found') {
            this.toastUtils.showToast('Usuario no encotrado.', 'danger');
          }
        });
    } else {
      this.toastUtils.showToast(
        'Tu usuario no tiene permisos para acceder a esta plataforma',
        'danger'
      );
    }
  }

  /**
   * * Function to logout a user
   *
   */
  public async logout(): Promise<void> {
    await this.auth.signOut();
    this.router.navigate(['login'], { replaceUrl: true });
  }
}
