import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DBCollectionName } from '../enums';
import { JornadasCalendar } from '../models';

@Injectable({ providedIn: 'root' })
export class JornadasCalendarService extends BaseService<JornadasCalendar> {
  constructor(angularFirestore: AngularFirestore) {
    super(DBCollectionName.JORNADAS_CALENDAR, angularFirestore);
  }
}
