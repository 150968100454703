import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IonCol,
  IonCard,
  IonCardContent,
  IonRow,
  IonInput,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonIcon,
  ModalController,
} from '@ionic/angular/standalone';
import {
  DateFormatPipe,
  ReservesTournament,
  ReservesTournamentService,
  ReservesTournamentTable,
} from '@lnfp/data-access';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastUtils } from '@lnfp/ui';
import { addIcons } from 'ionicons';
import { addCircle } from 'ionicons/icons';
import { CreateTournamentComponent } from './components/create-torunament/create-tournament.component';
import { AddTeamModalComponent } from './components/add-team-modal/add-team-modal.component';

@Component({
  selector: 'app-reserve-tournaments',
  standalone: true,
  imports: [
    IonButton,
    IonInput,
    IonRow,
    IonCardContent,
    IonCard,
    IonCol,
    CommonModule,
    ReactiveFormsModule,
    IonSelect,
    IonSelectOption,
    IonItem,
    DateFormatPipe,
    IonIcon,
  ],
  templateUrl: './reserve-tournaments.component.html',
  styleUrl: './reserve-tournaments.component.scss',
})
export class ReserveTournamentsComponent implements OnInit {
  public tournamentsList: ReservesTournament[] = [];
  public currentTournament: ReservesTournament | undefined = undefined;
  public currentTournamentTable: ReservesTournamentTable[] = [];
  public tournamentForm: FormGroup | undefined = undefined;

  constructor(
    private reservesTournamentService: ReservesTournamentService,
    private formBuilder: FormBuilder,
    private toastUtil: ToastUtils,
    private modalCtrl: ModalController
  ) {}

  async ngOnInit(): Promise<void> {
    addIcons({ addCircle });
    // * Creating form
    this.tournamentForm = this.formBuilder.group({
      localTeamId: ['', Validators.required],
      localTeamGoals: [0, Validators.required],
      visitantTeamId: ['', Validators.required],
      visitantTeamGoals: [0, Validators.required],
    });
    await this.getTournamentsList();
    this.getActiveTournament();
    await this.getActiveTournamentTable();
  }

  /**
   * * Getting tournament list
   *
   */
  private async getTournamentsList(): Promise<void> {
    this.tournamentsList = await this.reservesTournamentService.list((query) =>
      query.orderBy('created_at', 'desc').limit(10)
    );
  }

  /**
   * * Getting active tournament data
   *
   */
  private getActiveTournament(): void {
    this.currentTournament = this.tournamentsList.find(
      (tournament) => tournament.is_active
    );
  }

  private async getActiveTournamentTable(): Promise<void> {
    if (this.currentTournament) {
      const documentData = (
        await this.reservesTournamentService
          .getRef(this.currentTournament.id)
          .collection('table')
          .get()
      ).docs;
      for (const document of documentData) {
        const data = document.data() as ReservesTournamentTable;
        this.currentTournamentTable.push(data);
      }
      this.currentTournamentTable = this.currentTournamentTable.sort(
        (a, b) =>
          // * Sorting by points and by goals difference
          b.pg * 3 + b.pe - (a.pg * 3 + a.pe) || b.gf - b.gc - (a.gf - a.gc)
      );
    }
  }

  public async updateTable(): Promise<void> {
    if (this.tournamentForm) {
      const dataResults = {
        localTeamId: this.tournamentForm.value['localTeamId'],
        localTeamGoals: this.tournamentForm.value['localTeamGoals'],
        visitantTeamId: this.tournamentForm.value['visitantTeamId'],
        visitantTeamGoals: this.tournamentForm.value['visitantTeamGoals'],
      };

      // * Property to get the local team object from array
      const localTeamObject: ReservesTournamentTable | undefined =
        this.currentTournamentTable.find(
          (team) => team.uid === dataResults.localTeamId
        );

      // * Property to get the visitant team object from array
      const visitantTeamObject: ReservesTournamentTable | undefined =
        this.currentTournamentTable.find(
          (team) => team.uid === dataResults.visitantTeamId
        );

      if (localTeamObject && visitantTeamObject) {
        if (dataResults.localTeamGoals > dataResults.visitantTeamGoals) {
          // Means local wins
          const localDataObject = {
            pj: localTeamObject.pj + 1,
            pg: localTeamObject.pg + 1,
            gf: localTeamObject.gf + dataResults.localTeamGoals,
            gc: localTeamObject.gc + dataResults.visitantTeamGoals,
          };
          const visitantDataObject = {
            pj: visitantTeamObject.pj + 1,
            pp: visitantTeamObject.pp + 1,
            gf: visitantTeamObject.gf + dataResults.visitantTeamGoals,
            gc: visitantTeamObject.gc + dataResults.localTeamGoals,
          };
          try {
            await this.reservesTournamentService
              .getRef(this.currentTournament?.uid as string)
              .collection('table')
              .doc(dataResults.localTeamId)
              .update(localDataObject);

            await this.reservesTournamentService
              .getRef(this.currentTournament?.uid as string)
              .collection('table')
              .doc(dataResults.visitantTeamId)
              .update(visitantDataObject);
            setTimeout(() => {
              this.toastUtil.showToast(
                'Tabla actualizada correctamente',
                'success'
              );
            }, 1000);
            location.reload();
          } catch (e) {
            this.toastUtil.showToast('Error al actualizar tabla', 'danger');
            console.error('Error al crear tabla', e);
          }
        } else if (dataResults.localTeamGoals < dataResults.visitantTeamGoals) {
          // Means local lose
          const localDataObject = {
            pj: localTeamObject.pj + 1,
            pp: localTeamObject.pp + 1,
            gf: localTeamObject.gf + dataResults.localTeamGoals,
            gc: localTeamObject.gc + dataResults.visitantTeamGoals,
          };
          const visitantDataObject = {
            pj: visitantTeamObject.pj + 1,
            pg: visitantTeamObject.pg + 1,
            gf: visitantTeamObject.gf + dataResults.visitantTeamGoals,
            gc: visitantTeamObject.gc + dataResults.localTeamGoals,
          };
          try {
            await this.reservesTournamentService
              .getRef(this.currentTournament?.uid as string)
              .collection('table')
              .doc(dataResults.localTeamId)
              .update(localDataObject);

            await this.reservesTournamentService
              .getRef(this.currentTournament?.uid as string)
              .collection('table')
              .doc(dataResults.visitantTeamId)
              .update(visitantDataObject);
            setTimeout(() => {
              this.toastUtil.showToast(
                'Tabla actualizada correctamente',
                'success'
              );
            }, 1000);
            location.reload();
          } catch (e) {
            this.toastUtil.showToast('Error al actualizar tabla', 'danger');
            console.error('Error al crear tabla', e);
          }
        } else {
          // Means a draw
          const localDataObject = {
            pj: localTeamObject.pj + 1,
            pe: localTeamObject.pe + 1,
            gf: localTeamObject.gf + dataResults.localTeamGoals,
            gc: localTeamObject.gc + dataResults.visitantTeamGoals,
          };
          const visitantDataObject = {
            pj: visitantTeamObject.pj + 1,
            pe: visitantTeamObject.pe + 1,
            gf: visitantTeamObject.gf + dataResults.visitantTeamGoals,
            gc: visitantTeamObject.gc + dataResults.localTeamGoals,
          };
          try {
            await this.reservesTournamentService
              .getRef(this.currentTournament?.uid as string)
              .collection('table')
              .doc(dataResults.localTeamId)
              .update(localDataObject);

            await this.reservesTournamentService
              .getRef(this.currentTournament?.uid as string)
              .collection('table')
              .doc(dataResults.visitantTeamId)
              .update(visitantDataObject);
            setTimeout(() => {
              this.toastUtil.showToast(
                'Tabla actualizada correctamente',
                'success'
              );
            }, 1000);
            location.reload();
          } catch (e) {
            this.toastUtil.showToast('Error al actualizar tabla', 'danger');
            console.error('Error al crear tabla', e);
          }
        }
      }
    }
  }

  /**
   * * Opens create tournament modal
   *
   */
  public async openCreateNewTournament(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: CreateTournamentComponent,
      cssClass: 'small-modal',
      componentProps: { currentTournamentId: this.currentTournament?.uid },
    });
    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      location.reload();
    }
  }

  /**
   * * Opens add team modal
   *
   */
  public async openAddTeamModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: AddTeamModalComponent,
      cssClass: 'small-modal',
      componentProps: { currentTournamentId: this.currentTournament?.uid },
    });
    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.currentTournamentTable = [];
      await this.getTournamentsList();
      this.getActiveTournament();
      await this.getActiveTournamentTable();
    }
  }
}
