import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { JornadasCalendar, JornadasCalendarService } from '@lnfp/data-access';
import { MatButtonModule } from '@angular/material/button';
import { ToastUtils } from '@lnfp/ui';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatPaginatorModule, MatButtonModule],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent implements OnInit {
  public displayedColumns: string[] = ['name', 'status', 'actions'];
  public jornadas: JornadasCalendar[] = [];
  public dataSource = new MatTableDataSource<JornadasCalendar>();

  constructor(private jornadasCalendarService: JornadasCalendarService, private toastUtils: ToastUtils) {}

  async ngOnInit(): Promise<void> {
    await this.getJornadas();
  }

  /**
   * * Method to retrieve actual journeys from database
   *
   */
  private async getJornadas(): Promise<void> {
    const jornadasData = await this.jornadasCalendarService.list();
    this.jornadas = jornadasData.sort((a, b) => {
      return Number(a.roundNumber) - Number(b.roundNumber);
    });
    this.dataSource.data = this.jornadas;
  }

  /**
   * * Method to update a Jornada object
   *
   * @param jornadaObject as JornadasCalendar
   * @param type as boolean
   */
  public async updateJornada(
    jornadaObject: JornadasCalendar,
    type: boolean
  ): Promise<void> {
    const activeJornada = this.jornadas.find((item) => item.isActive);
    jornadaObject.isActive = type;
    await this.jornadasCalendarService.update(jornadaObject);
    if (activeJornada && type) {
      activeJornada.isActive = false;
      await this.jornadasCalendarService.update(activeJornada);
    }
    this.toastUtils.showToast("Jornada actualizada éxitosamente", 'success');
  }
}
