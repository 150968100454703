import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Users, UsersService } from '@lnfp/data-access';
import { MatButtonModule } from '@angular/material/button';
import jsonToCsvExport from 'json-to-csv-export';
import { LoaderUtils } from '@lnfp/ui';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    IonicModule,
    FormsModule,
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss',
})
export class UsersComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

  public displayedColumns: string[] = ['fullname', 'email', 'is_active'];
  public users: Users[] = [];
  public dataSource = new MatTableDataSource<Users>();
  public userToSearch = '';
  public usersToExport: Users[] = [];

  constructor(
    private usersService: UsersService,
    private loaderService: LoaderUtils
  ) {}

  async ngAfterViewInit(): Promise<void> {
    this.dataSource.paginator = this.paginator;
    await this.getUsersPaginated();
  }

  /**
   * * Function to retrieve users from database
   * @param lastDocumentId as string
   */
  public async getUsersPaginated(lastDocumentId?: string): Promise<void> {
    if (lastDocumentId !== undefined) {
      const usersArray = await this.usersService.list((query) =>
        query.orderBy('created_at', 'desc').startAfter(lastDocumentId).limit(25)
      );
      this.users.push(...usersArray);
    } else {
      this.users = await this.usersService.list((query) =>
        query.orderBy('created_at', 'desc').limit(25)
      );
    }
    this.dataSource.data = this.users;
  }

  /**
   * * Function to load more users from table
   * @param event
   */
  public loadMoreUsers(event: { pageIndex: number; pageSize: number }): void {
    const startIndex = event.pageIndex * event.pageSize;
    const lastDocument =
      startIndex > 0
        ? this.dataSource.data[this.dataSource.data.length - 1]
        : undefined;
    this.getUsersPaginated(lastDocument?.uid);
  }

  /**
   * * Method to export users
   *
   */
  public async exportToExcel(): Promise<void> {
    await this.loaderService.showLoader(
      'Descargando excel, por favor espera...'
    );
    const headers = [
      {
        key: 'fullname',
        label: 'Nombre Completo',
      },
      {
        key: 'email',
        label: 'Correo Electrónico',
      },
      {
        key: 'phone_number',
        label: 'Teléfono',
      },
    ];
    if (this.usersToExport.length <= 0) {
      this.usersToExport = await this.usersService.list((query) =>
        query.orderBy('created_at', 'desc')
      );
    }
    jsonToCsvExport({ data: this.usersToExport, headers });
    await this.loaderService.hideLoader();
  }

  /**
   * * Method to search a user
   *
   */
  public async searchUser(): Promise<void> {
    await this.loaderService.showLoader(
      'Buscando usuario, por favor espera...'
    );
    if (this.usersToExport.length <= 0) {
      this.usersToExport = await this.usersService.list((query) =>
        query.orderBy('created_at', 'desc')
      );
    }
    this.userToSearch = this.userToSearch.toLowerCase();
    const usersMatch = this.usersToExport.filter((user) =>
      user.email.includes(this.userToSearch)
    );
    this.dataSource.data = usersMatch;
    await this.loaderService.hideLoader();
  }
}
