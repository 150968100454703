<div class="voting-center">
  <div class="voting-center-header">
    <p>Voting Center</p>
  </div>

  <ion-segment value="room" mode="ios" class="mt-3" [(ngModel)]="segmentValue">
    <ion-segment-button value="room">
      <ion-label>Crear Sala</ion-label>
    </ion-segment-button>
    <ion-segment-button value="player">
      <ion-label>Crear Participante</ion-label>
    </ion-segment-button>
  </ion-segment>

  <div class="room-content" [ngSwitch]="segmentValue">
    <div *ngSwitchCase="'room'" class="mt-4">
      <ion-card>
        @if (roomForm) {
        <form [formGroup]="roomForm">
          <ion-card-content>
            <p class="room-content__title">Crear Sala</p>
            <ion-row>
              <ion-col size="6">
                <ion-input
                  formControlName="name"
                  label="Nombre de la sala"
                  label-placement="floating"
                  fill="outline"
                  placeholder="Ingresa el nombre de la sala"
                ></ion-input>
              </ion-col>
              <ion-col size="6">
                <ion-input
                  formControlName="banner_img"
                  label="Imagen de cover"
                  label-placement="floating"
                  fill="outline"
                  placeholder="Ingrese la URL de la imagen"
                ></ion-input>
              </ion-col>
            </ion-row>
            <div class="d-flex justify-content-center mt-2">
              <ion-button [disabled]="roomForm.invalid" (click)="saveRoom()"
                >Guardar</ion-button
              >
            </div>
          </ion-card-content>
        </form>
        }
      </ion-card>

      <div class="mat-elevation-z8 mt-3">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acción</th>
            <td mat-cell *matCellDef="let element">
              <ion-button
                color="secondary"
                (click)="updateRoom(element, true)"
                >{{ element.isActive ? 'Desactivar' : 'Activar' }}</ion-button
              >
              <ion-button
                color="secondary"
                (click)="updateRoom(element, false)"
                >{{
                  element.closeRoom ? 'Abrir Sala' : 'Cerrar Sala'
                }}</ion-button
              >
              <ion-button color="danger" (click)="deleteRoom(element)"
                >Eliminar</ion-button
              >
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>

    <div *ngSwitchCase="'player'" class="mt-4">
      <ion-card>
        @if (playerForm) {
        <form [formGroup]="playerForm">
          <ion-card-content>
            <p class="room-content__title">Crear Participante</p>
            <ion-row>
              <ion-col size="6">
                <ion-input
                  formControlName="player_name"
                  label="Nombre del participante"
                  label-placement="floating"
                  fill="outline"
                  placeholder="Ingresa el nombre de la sala"
                ></ion-input>
              </ion-col>
              <ion-col size="6">
                <ion-input
                  formControlName="player_image"
                  label="Imagen de cover"
                  label-placement="floating"
                  fill="outline"
                  placeholder="Ingrese la URL de la imagen"
                ></ion-input>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="10">
                <mat-form-field class="mat-form-field">
                  <mat-label>Selecciona una opción</mat-label>
                  <mat-select formControlName="room_id">
                    <mat-option>Ninguno</mat-option>
                    @for (room of votingCenterRooms; track $index) {
                    <mat-option [value]="room.id">{{ room.name }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </ion-col>
            </ion-row>
            <div class="d-flex justify-content-center mt-2">
              <ion-button [disabled]="playerForm.invalid" (click)="savePlayer()"
                >Guardar</ion-button
              >
            </div>
          </ion-card-content>
        </form>
        }
      </ion-card>
    </div>
  </div>
</div>
