import {
  AngularFirestore,
  DocumentData,
  DocumentReference,
  QueryFn,
} from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirestoreCollection } from '../models/collection.interface';

/**
 * * Generic Service used to save code and share common crud function accross other services
 */
export abstract class BaseService<T extends FirestoreCollection> {
  protected collection = '';

  /**
   * * constructor for BaseService
   *
   * @param collectionName name of the collection in firebase
   * @param angularFirestore AngularFirestore
   */
  constructor(
    collectionName: string,
    protected angularFirestore: AngularFirestore
  ) {
    if (!collectionName) {
      console.error('base.service.ts: No collection name');
    }
    this.collection = collectionName;
  }

  /**
   * * This retrieves a doc from Firebase directly by ID
   *
   * @param id of Document to retrieve
   * @returns a promise of the document
   */
  public get(id: string): Promise<T> {
    try {
      return firstValueFrom(
        this.angularFirestore
          .collection(this.collection)
          .doc(id)
          .get()
          .pipe(
            map((doc) => {
              const data = doc.data();
              if (data) {
                return { ...(doc.data() as T) };
              } else {
                // error log here
                return undefined as unknown as T;
              }
            })
          )
      );
    } catch (e) {
      // error log here
      return Promise.reject(e);
    }
  }

  /**
   * * This get will help to retrieve document reference
   *
   * @param id of Document reference to retrieve
   * @returns returns a reference of the doc
   */
  public getRef(id: string): DocumentReference<T> {
    try {
      return this.angularFirestore.collection<T>(this.collection).doc(id).ref;
    } catch (error) {
      console.error('base.service.ts', 'error getting Ref', error);
      throw error;
    }
  }
  /**
   * * Query to retrieve documents from firebase collection
   *
   * @param query Parameter sent to filter out the docs at firebase if needed
   * @returns array of documents from the collection
   */
  public list(query?: QueryFn<DocumentData>): Promise<T[]> {
    try {
      return firstValueFrom(
        this.angularFirestore
          .collection(this.collection, query)
          .get()
          .pipe(
            map((actions) =>
              actions.docs.map((a) => {
                const data = a.data() as T;
                const id = a.id;
                return { ...data, id };
              })
            )
          )
      );
    } catch (e) {
      console.error('base.service.ts', 'list()', e);
      return Promise.reject(e);
    }
  }

  /**
   *
   * @param reference reference to process
   * @returns reference data
   */
  public async processReference(reference: DocumentReference<T>): Promise<T> {
    const document = (await reference.get()).data() as T;
    return document;
  }

  /**
   *
   */
  public async add(item: T): Promise<T> {
    try {
      if (!item.id) {
        item.id = this.createId();
      }

      return this.angularFirestore
        .collection<T>(this.collection)
        .doc(item.id.length > 5 ? item.id : item.uid)
        .set({ ...item })
        .catch((e) =>
          console.error('base.service.ts --> error adding item', item, e)
        )
        .then(() => ({
          ...item,
        }));
    } catch (e) {
      console.error('base.service.ts', e);
      return Promise.reject(e);
    }
  }

  /**
   * * Update function for all items
   *
   * @param item T - The item to update
   * @returns Promise<T> of the type you passed in
   */
  public async update(item: T): Promise<T> {
    return this.angularFirestore
      .collection<T>(this.collection)
      .doc(item.id.length > 5 ? item.id : item.uid)
      .update({ ...item })
      .catch((e) => {
        console.error('base.service.ts --> error updating', e);
      })
      .then(() => {
        return {
          ...item,
        };
      });
  }

  public async set(item: T): Promise<T> {
    return this.angularFirestore
      .collection<T>(this.collection)
      .doc(item.id)
      .set({ ...item })
      .catch((e) => {
        console.error('base.service.ts --> error setting', e);
      })
      .then(() => {
        return {
          ...item,
        };
      });
  }

  public async delete(id: string): Promise<void> {
    return this.angularFirestore
      .collection(this.collection)
      .doc<T>(id)
      .delete();
  }

  /**
   * ID creator for firebase
   *
   * @returns string
   */
  public createId(): string {
    return this.angularFirestore.createId();
  }
}
