import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IonInput,
  IonItem,
  IonButton,
  ModalController,
} from '@ionic/angular/standalone';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ReservesTournament,
  ReservesTournamentService,
} from '@lnfp/data-access';
import { ToastUtils } from '@lnfp/ui';

@Component({
  selector: 'app-create-tournament',
  standalone: true,
  imports: [IonButton, IonItem, CommonModule, IonInput, ReactiveFormsModule],
  templateUrl: './create-tournament.component.html',
  styleUrl: './create-tournament.component.scss',
})
export class CreateTournamentComponent implements OnInit {
  @Input() currentTournamentId = '';
  public createTournamentForm: FormGroup | undefined = undefined;

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private reservesTournamentService: ReservesTournamentService,
    private toastUtil: ToastUtils
  ) {}

  ngOnInit(): void {
    this.createTournamentForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  /**
   * * Function to close modal
   *
   * @returns modalCtrl.dismiss
   */
  public cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  /**
   * * Function to create tournament
   *
   */
  public async createTournament(): Promise<void> {
    const idToUse = this.reservesTournamentService.createId();
    const tmpTournamentObject: ReservesTournament = {
      uid: idToUse,
      is_active: true,
      name: this.createTournamentForm?.get('name')?.value,
      created_at: new Date(),
      id: idToUse,
    };
    await this.reservesTournamentService
      .add(tmpTournamentObject)
      .then(async () => {
        await this.reservesTournamentService
          .getRef(this.currentTournamentId)
          .update({ is_active: false });
        this.toastUtil.showToast('Torneo creado éxitosamente', 'success');
        this.modalCtrl.dismiss(null, 'confirm');
      })
      .catch((e) => {
        this.toastUtil.showToast(
          'Error al crear torneo, por favor intenta de nuevo',
          'danger'
        );
        console.error(e);
      });
  }
}
