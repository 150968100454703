<div class="users">
  <div class="users-header">
    <p>Usuarios del app</p>
  </div>
  <div class="d-flex justify-content-between mt-3">
    <button mat-raised-button (click)="exportToExcel()">
      Exportar a Excel
    </button>
    <div class="d-flex">
      <ion-input
        label="Buscar por email"
        label-placement="floating"
        fill="outline"
        placeholder="Ingresa el correo"
        class="w-100"
        [(ngModel)]="userToSearch"
      ></ion-input>
      <button
        mat-raised-button
        (click)="searchUser()"
        class="m-2"
        [disabled]="userToSearch.length <= 2"
      >
        Buscar
      </button>
      <button
        mat-raised-button
        (click)="getUsersPaginated()"
        class="m-2"
        [disabled]="userToSearch.length <= 2"
      >
        Limipiar
      </button>
    </div>
  </div>
  <div class="mat-elevation-z8 mt-3">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="fullname">
        <th mat-header-cell *matHeaderCellDef>Nombre</th>
        <td mat-cell *matCellDef="let element">{{ element.fullname }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="is_active">
        <th mat-header-cell *matHeaderCellDef>Miembro desde</th>
        <td mat-cell *matCellDef="let element">{{ element.created_at }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[24]"
      (page)="loadMoreUsers($event)"
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</div>
