import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Color } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export class ToastUtils {
  constructor(private toastController: ToastController) {}

  /**
   * * Method to show a toast
   *
   * @param message
   */
  public async showToast(message: string, color: Color): Promise<void> {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      position: 'top',
      color,
    });
    await toast.present();
  }
}
