export const environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyBJDm3tZCdodHo7fZ9uE1nEwnDZqbigxA4',
    authDomain: 'lnphn-2096e.firebaseapp.com',
    databaseURL: 'https://lnphn-2096e.firebaseio.com',
    projectId: 'lnphn-2096e',
    storageBucket: 'lnphn-2096e.appspot.com',
    messagingSenderId: '1061690800950',
    appId: '1:1061690800950:web:7df828abee6d6d9d671197',
    measurementId: 'G-11GM6RY1KM',
  },
  honeybadgerApiKey: 'hbp_kCrh42XOGXj6nOT7cn9WUl9nYGXt3H3u2ZpB',
};
