import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Highlights, HighlightsService } from '@lnfp/data-access';
import {
  IonCardContent,
  IonCard,
  IonInput,
  IonCol,
  IonRow,
  IonButton,
} from '@ionic/angular/standalone';
import { ToastUtils } from '@lnfp/ui';

@Component({
  selector: 'app-add-highlights',
  standalone: true,
  imports: [
    IonButton,
    IonRow,
    IonCol,
    IonInput,
    IonCard,
    IonCardContent,
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './add-highlights.component.html',
  styleUrl: './add-highlights.component.scss',
})
export class AddHighlightsComponent implements OnInit {
  public highlightsForm: FormGroup | undefined = undefined;
  public highlightsList: Highlights[] = [];

  constructor(
    private highlightsService: HighlightsService,
    private formBuilder: FormBuilder,
    private toastUtils: ToastUtils
  ) {}

  async ngOnInit(): Promise<void> {
    this.highlightsForm = this.formBuilder.group({
      id_jornada: [null, [Validators.required]],
      banner_image: ['', [Validators.required]],
      match_name: ['', [Validators.required]],
      url_video: ['', [Validators.required]],
    });
    await this.getHighlights();
  }

  /**
   * * Function to retrieve highlights
   *
   */
  public async getHighlights(): Promise<void> {
    this.highlightsList = await this.highlightsService.list((query) =>
      query.limit(10).orderBy('created_at', 'desc')
    );
  }

  /**
   * * Method to save a highlight into database
   *
   */
  public async createHighlight(): Promise<void> {
    if (this.highlightsForm?.valid) {
      const tmpHighlightItem: Highlights = {
        match_name: this.highlightsForm.value['match_name'],
        id_jornada: this.highlightsForm.value['id_jornada'],
        url_video: this.highlightsForm.value['url_video'],
        banner_image: this.highlightsForm.value['banner_image'],
        status: false,
        id: this.highlightsService.createId(),
        created_at: new Date(),
      };
      await this.highlightsService
        .add(tmpHighlightItem)
        .then(async () => {
          this.highlightsForm?.reset();
          await this.toastUtils.showToast(
            'Highlight video agregado éxitosamente',
            'success'
          );
          await this.getHighlights();
        })
        .catch(async (e) => {
          await this.toastUtils.showToast(
            `Error al crear highlight: ${e}`,
            'danger'
          );
        });
    }
  }

  public async updateHighlightStatus(highlightItem: Highlights): Promise<void> {
    if (highlightItem.status) highlightItem.status = false;
    else highlightItem.status = true;
    this.highlightsService.update(highlightItem).then(async () => {
      await this.toastUtils.showToast('Jornada actualizada', 'success');
    });
  }
}
