export var csvGenerateRow = function (row, headerKeys, delimiter) {
  var needsQuoteWrapping = new RegExp("[\"".concat(delimiter, "\r\n]"));
  return headerKeys.map(function (header) {
    var fieldName = typeof header === "string" ? header : header.key;
    var value = row[fieldName];
    if (typeof value === "number" || typeof value === "boolean") return "".concat(value);
    if (!value) return "";
    if (typeof value !== "string") {
      value = String(value);
    }
    /* RFC-4180
    6.  Fields containing line breaks (CRLF), double quotes, and commas should be enclosed in double-quotes.
    7.  If double-quotes are used to enclose fields, then a double-quote inside a field must be escaped by preceding it with
     another double quote. For example: "aaa","b""bb","ccc"
     In order to support something other than commas as delimiters, we will substitute delimiter for comma in rule 6,
     although use of a double quotes or CRLF as delimiter is unsupported. */
    if (needsQuoteWrapping.test(value)) {
      return "\"".concat(value.replace(/"/g, '""'), "\"");
    } else {
      return value;
    }
  }).join(delimiter);
};
var getAllUniqueKeys = function (data) {
  var keys = new Set();
  for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
    var row = data_1[_i];
    for (var key in row) {
      if (row.hasOwnProperty(key)) {
        keys.add(key);
      }
    }
  }
  return Array.from(keys);
};
export var csvGenerate = function (data, headers, delimiter) {
  var headerKeys = headers !== null && headers !== void 0 ? headers : getAllUniqueKeys(data);
  var headerRow = headerKeys.map(function (header) {
    return typeof header === "string" ? header : header.label;
  });
  var csv = data.map(function (row) {
    return csvGenerateRow(row, headerKeys, delimiter);
  });
  csv.unshift(headerRow.join(delimiter));
  return csv.join("\r\n");
};