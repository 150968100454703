import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DBCollectionName } from '../enums';
import { ReservesTournament } from '../models';

@Injectable({ providedIn: 'root' })
export class ReservesTournamentService extends BaseService<ReservesTournament> {
  constructor(angularFirestore: AngularFirestore) {
    super(DBCollectionName.RERSERVES_TOURNAMENT, angularFirestore);
  }
}
