<div class="tournament">
  <div class="tournament-header">
    <p>Torneo de reservas</p>
  </div>
  <ion-card>
    @if (tournamentForm) {
    <form [formGroup]="tournamentForm">
      <ion-card-content>
        <p class="tournament-content__title">{{ currentTournament?.name }}</p>
        <ion-row>
          <ion-col size="6">
            <ion-item>
              <ion-select
                label="Selecciona el equipo local"
                label-placement="floating"
                okText="Seleccionar"
                cancelText="Cancelar"
                formControlName="localTeamId"
              >
                @for (team of currentTournamentTable; track $index) {
                <ion-select-option [value]="team.uid">{{
                  team.team_name
                }}</ion-select-option>
                }
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col size="6">
            <ion-input
              formControlName="localTeamGoals"
              label="Goles equipo local"
              label-placement="floating"
              fill="outline"
              type="number"
              placeholder="Total de goles"
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="pt-2">
          <ion-col size="6">
            <ion-item>
              <ion-select
                label="Selecciona el equipo visitante"
                label-placement="floating"
                okText="Seleccionar"
                cancelText="Cancelar"
                formControlName="visitantTeamId"
              >
                @for (team of currentTournamentTable; track $index) {
                <ion-select-option [value]="team.uid">{{
                  team.team_name
                }}</ion-select-option>
                }
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col size="6">
            <ion-input
              formControlName="visitantTeamGoals"
              label="Goles equipo visitante"
              label-placement="floating"
              fill="outline"
              type="number"
              placeholder="Total de goles"
            ></ion-input>
          </ion-col>
        </ion-row>
        <div class="tournament-content__btn d-flex justify-content-center">
          <ion-button
            [disabled]="tournamentForm.invalid"
            (click)="updateTable()"
            >Actualizar Tabla</ion-button
          >
          <ion-button (click)="openAddTeamModal()"
            >Añadir equipo a tabla</ion-button
          >
        </div>
      </ion-card-content>
    </form>
    }
  </ion-card>
  @if (currentTournamentTable) {
  <div class="table-responsive pt-3">
    <table class="table align-items-center table-flush">
      <thead class="thead-light">
        <tr>
          <th scope="col">Posición</th>
          <th scope="col">Equipo</th>
          <th scope="col">PJ</th>
          <th scope="col">PG</th>
          <th scope="col">PE</th>
          <th scope="col">PP</th>
          <th scope="col">GF</th>
          <th scope="col">GC</th>
          <th scope="col">DG</th>
          <th scope="col">PTS</th>
        </tr>
      </thead>
      @if (currentTournamentTable.length <= 0) {
      <div>Ningún equipo creado para esta tabla</div>
      }
      <tbody>
        @for (team of currentTournamentTable; track $index) {
        <tr>
          <td>
            {{ $index + 1 }}
          </td>
          <td>
            {{ team.team_name }}
          </td>
          <td>
            {{ team.pj }}
          </td>
          <td>
            {{ team.pg }}
          </td>
          <td>
            {{ team.pe }}
          </td>
          <td>
            {{ team.pp }}
          </td>
          <td>
            {{ team.gf }}
          </td>
          <td>
            {{ team.gc }}
          </td>
          <td>
            {{ team.gf - team.gc }}
          </td>
          <td>
            {{ team.pj > 0 ? team.pg * 3 + team.pe : 0 }}
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
  } @if (tournamentsList) {
  <div class="tournament-header mt-5">
    <p>
      Lista de torneos
      <ion-icon
        name="add-circle"
        class="add-icon"
        (click)="openCreateNewTournament()"
      ></ion-icon>
    </p>
  </div>
  <div class="table-responsive pt-3">
    <table class="table align-items-center table-flush">
      <thead class="thead-light">
        <tr>
          <th scope="col"></th>
          <th scope="col">Nombre</th>
          <th scope="col">Status</th>
          <th scope="col">Fecha de Creación</th>
        </tr>
      </thead>
      <tbody>
        @for (item of tournamentsList; track $index) {
        <tr>
          <td>
            {{ $index + 1 }}
          </td>
          <td>
            {{ item.name }}
          </td>
          <td>
            {{ item.is_active ? 'Activa' : 'Inactiva' }}
          </td>
          <td>
            {{ item.created_at | dateFormat : 'dd-MM-yyyy' }}
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
  }
</div>
