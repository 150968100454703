import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import {
  medalOutline,
  logOutOutline,
  peopleOutline,
  settingsOutline,
  videocamOutline,
  calendarOutline,
  newspaperOutline,
  trophyOutline
} from 'ionicons/icons';
import { RouterModule } from '@angular/router';
import { AuthService } from '@lnfp/data-access';
export interface SidebarItems {
  name: string;
  iconPath: string;
  routePath: string;
}

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, IonIcon, RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  public sidebarItems: SidebarItems[] = [
    {
      name: 'Voting Center',
      iconPath: 'trophy-outline',
      routePath: 'app/voting-center',
    },
    {
      name: 'Primicias',
      iconPath: 'newspaper-outline',
      routePath: 'app/primicias',
    },
    {
      name: 'Highlights',
      iconPath: 'videocam-outline',
      routePath: 'app/highlights',
    },
    {
      name: 'Torneo de Reservas',
      iconPath: 'calendar-outline',
      routePath: 'app/torneo-reservas',
    },
    {
      name: 'Usuarios',
      iconPath: 'people-outline',
      routePath: 'app/usuarios',
    },
    {
      name: 'Configuración',
      iconPath: 'settings-outline',
      routePath: 'app/configuracion',
    },
  ];
  public itemSelected: SidebarItems = this.sidebarItems[1];

  constructor(private authService: AuthService) {
    addIcons({
      medalOutline,
      logOutOutline,
      peopleOutline,
      settingsOutline,
      videocamOutline,
      calendarOutline,
      newspaperOutline,
      trophyOutline
    });
  }

  public async logOut(): Promise<void> {
    await this.authService.logout();
  }
}
