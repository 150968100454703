import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IonSegmentButton,
  IonSegment,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonCardContent,
  IonCard,
  IonButton,
  IonSelect,
  IonSelectOption,
} from '@ionic/angular/standalone';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ClassicEditor,
  Bold,
  Italic,
  Paragraph,
  Undo,
  Essentials,
  Alignment,
  FontSize,
  Strikethrough,
  Heading,
  Link,
  ImageInsert,
  Image
} from 'ckeditor5';

import { News, NewsService } from '@lnfp/data-access';
import { ChangeEvent, CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ToastUtils } from '@lnfp/ui';

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonRow,
    IonInput,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    CommonModule,
    ReactiveFormsModule,
    CKEditorModule,
  ],
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss',
})
export class NewsComponent implements OnInit {
  public newsForm: FormGroup | undefined = undefined;
  public newsList: News[] = [];
  public editor = ClassicEditor;
  public config = {
    toolbar: [
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'alignment',
      'fontsize',
      'strikethrough',
      'heading',
      'link',
      'insertImage'
    ],
    plugins: [
      Bold,
      Italic,
      Paragraph,
      Essentials,
      Undo,
      Alignment,
      FontSize,
      Strikethrough,
      Heading,
      Link,
      Image,
      ImageInsert
    ],
    placeholder: 'Redacta tu nota',
  };
  public editorData = '';
  public isEditEnable = false;
  public newsToEdit: News | undefined = undefined;

  constructor(
    private newsService: NewsService,
    private formBuilder: FormBuilder,
    private toastUtils: ToastUtils
  ) {}

  async ngOnInit(): Promise<void> {
    this.newsForm = this.formBuilder.group({
      news_title: ['', [Validators.required]],
      cover_news: ['', [Validators.required]],
      type: ['', [Validators.required]],
    });
    await this.getNews();
  }
  /**
   * * Method to get News from firestore
   *
   */
  public async getNews(): Promise<void> {
    this.newsList = await this.newsService.list((ref) =>
      ref.orderBy('created_at', 'desc').limit(10)
    );
  }

  /**
   * * Method to delete a news
   *
   * @param id as string
   */
  public async deleteNews(id: string): Promise<void> {
    await this.newsService.delete(id).then(async () => {
      await this.toastUtils.showToast('Noticia eliminada', 'success');
      await this.getNews();
    });
  }

  /**
   * * Method called each time the user edit inside the editor
   *
   * @param editor as ChangeEvent<ClassicEditor>
   */
  public editorDataChanged({ editor }: ChangeEvent<ClassicEditor>): void {
    this.editorData = editor.getData();
  }

  /**
   * * Method to create a new document for news
   *
   */
  public async createNews(type: 'edit' | 'save'): Promise<void> {
    if (this.newsForm?.valid) {
      const tmpNewsItem: News = {
        id: this.newsService.createId(),
        type: this.newsForm.value['type'],
        news_title: this.newsForm.value['news_title'],
        cover_news: this.newsForm.value['cover_news'],
        html: this.editorData,
        status: '1',
        created_at: new Date(),
      };
      if (type === 'save') {
        this.newsService
          .add(tmpNewsItem)
          .then(async () => {
            this.newsForm?.reset();
            await this.toastUtils.showToast(
              'Noticia creada éxitosamente',
              'success'
            );
          })
          .catch(async (e) => {
            console.error('error creating news: ', e);
            await this.toastUtils.showToast(
              'Error al crear la jornada',
              'danger'
            );
          });
      } else {
        if (this.newsToEdit) {
          tmpNewsItem.id = this.newsToEdit.id;
          tmpNewsItem.status = this.newsToEdit.status;
          tmpNewsItem.created_at = this.newsToEdit.created_at;
          this.newsService
            .update(tmpNewsItem)
            .then(async () => {
              this.newsForm?.reset();
              this.newsToEdit = undefined;
              this.isEditEnable = false;
              this.editorData = '';
              await this.toastUtils.showToast('Noticia actualizada', 'success');
            })
            .catch(async (e) => {
              console.error('error updating news: ', e);
              await this.toastUtils.showToast(
                'Error al actualizar la noticia',
                'danger'
              );
            });
        }
      }
    }
  }

  /**
   * * Method to edit a news
   *
   * @param data as News
   */
  public editNew(data: News): void {
    if (this.newsForm) {
      this.newsToEdit = data;
      this.newsForm.controls['news_title'].setValue(data.news_title);
      this.newsForm.controls['cover_news'].setValue(data.cover_news);
      this.newsForm.controls['type'].setValue(data.type);
      this.editorData = data.html;
      this.isEditEnable = true;
    }
  }
}
