import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonInput,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from '@ionic/angular/standalone';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DBCollectionName, Jornadas, JornadasService } from '@lnfp/data-access';
import { ToastUtils } from '@lnfp/ui';
import { AddHighlightsComponent } from './add-highlights/add-highlights.component';
import { firstValueFrom, map } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-highlights',
  standalone: true,
  imports: [
    CommonModule,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    FormsModule,
    IonCard,
    IonCardContent,
    IonInput,
    IonRow,
    IonCol,
    IonButton,
    ReactiveFormsModule,
    AddHighlightsComponent,
  ],
  templateUrl: './highlights.component.html',
  styleUrl: './highlights.component.scss',
})
export class HighlightsComponent implements OnInit {
  public segmentValue: 'jornadas' | 'highlights' = 'jornadas'; // * Value when user switch from tabs
  public jornadasForm: FormGroup | undefined = undefined;
  public jornadasList: Jornadas[] = [];
  public lastJornadaId = 0;

  constructor(
    private formBuilder: FormBuilder,
    private jornadasService: JornadasService,
    private toastUtils: ToastUtils,
    private angularFirestore: AngularFirestore
  ) {}

  async ngOnInit(): Promise<void> {
    this.jornadasForm = this.formBuilder.group({
      id: [null, [Validators.required]],
      name: ['', [Validators.required]],
    });
    await this.getJornadas();
  }

  /**
   * * Method to get Jornadas from database
   *
   */
  async getJornadas(): Promise<void> {
    this.jornadasList = [];
    const jornadas = await firstValueFrom(
      this.angularFirestore
        .collection(DBCollectionName.JORNADAS_HIGHLIGHTS, (query) =>
          query.limit(20).orderBy('id', 'desc')
        )
        .get()
        .pipe(
          map((actions) =>
            actions.docs.map((a) => {
              const data = a.data() as Jornadas;
              return { ...data } as Jornadas;
            })
          )
        )
    );
    this.jornadasList = jornadas;
    this.jornadasList = this.jornadasList.filter(
      (item) => typeof item.id === 'number'
    );
  }

  /**
   * * Function to update a Jornada item
   *
   * @param jornadaItem as Jornadas
   */
  public updateJornada(jornadaItem: Jornadas): void {
    if (jornadaItem.isActive) jornadaItem.isActive = false;
    else jornadaItem.isActive = true;
    this.jornadasService.update(jornadaItem).then(async () => {
      await this.toastUtils.showToast('Jornada actualizada', 'success');
    });
  }

  /**
   * * Method to create a Jornada
   *
   */
  public async createJornada(): Promise<void> {
    if (this.jornadasForm?.valid) {
      const tmpJornadaItem: Jornadas = {
        isActive: false,
        id: this.jornadasForm.value['id'],
        name: this.jornadasForm.value['name'],
        uid: this.jornadasService.createId(),
      };
      this.jornadasService
        .add(tmpJornadaItem)
        .then(async () => {
          this.jornadasForm?.reset();
          await this.toastUtils.showToast(
            'Jornada creada éxitosamente',
            'success'
          );
          await this.getJornadas();
        })
        .catch(async (e) => {
          await this.toastUtils.showToast(
            'Error al crear la jornada',
            'danger'
          );
        });
    }
  }
}
