<div class="news">
  <div class="news-header">
    <p>Primicias</p>
  </div>
  <ion-card>
    @if (newsForm) {
    <form [formGroup]="newsForm">
      <ion-card-content>
        <p class="news-content__title">Crear Primicia</p>
        <ion-row>
          <ion-col size="6">
            <ion-input
              formControlName="news_title"
              label="Titulo"
              label-placement="floating"
              fill="outline"
              placeholder="Titulo de la nota"
            ></ion-input>
          </ion-col>
          <ion-col size="6">
            <ion-input
              formControlName="cover_news"
              label="Imagen de cover"
              label-placement="floating"
              fill="outline"
              placeholder="Ingrese la URL de la image"
            ></ion-input>
          </ion-col>
        </ion-row>

        <ckeditor
          #newsEditor
          [editor]="editor"
          [config]="config"
          [data]="editorData"
          (change)="editorDataChanged($event)"
        ></ckeditor>

        <ion-row>
          <ion-col size="6">
            <ion-select
              aria-label="Tipo"
              placeholder="Seleccione el tipo"
              formControlName="type"
            >
              <ion-select-option value="primicias">Primicia</ion-select-option>
              <ion-select-option value="noticias">Noticia</ion-select-option>
            </ion-select>
          </ion-col>
        </ion-row>
        <div class="news-content__btn">
          <ion-button
            [disabled]="newsForm.invalid"
            (click)="createNews(isEditEnable ? 'edit' : 'save')"
            >{{ isEditEnable ? 'Actualizar' : 'Guardar ' }}</ion-button
          >
        </div>
      </ion-card-content>
    </form>
    }
  </ion-card>
  <div class="news-list p-3">
    <p class="news-content__title mt-4">Lista de Noticias</p>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nombre</th>
          <th scope="col">Acción</th>
        </tr>
      </thead>
      <tbody>
        @if (newsList.length > 0) { @for (news of newsList; track $index) {
        <tr>
          <th scope="row">{{ $index + 1 }}</th>
          <td>{{ news.news_title }}</td>
          <td>
            <div class="d-flex">
              <ion-button (click)="deleteNews(news.id)">Eliminar</ion-button>
              <ion-button (click)="editNew(news)">Editar</ion-button>
            </div>
          </td>
        </tr>
        } }
      </tbody>
    </table>
  </div>
</div>
