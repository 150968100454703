import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IonButton,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonInput,
} from '@ionic/angular/standalone';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  VotingCenterClass,
  VotingCenterPlayerClass,
  VotingCenterService,
} from '@lnfp/data-access';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { ToastUtils } from '@lnfp/ui';
import Honeybadger from '@honeybadger-io/js';

@Component({
  selector: 'app-voting-center',
  standalone: true,
  imports: [
    IonCol,
    IonRow,
    CommonModule,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    FormsModule,
    MatTableModule,
    IonButton,
    IonCard,
    IonCardContent,
    ReactiveFormsModule,
    IonInput,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
  ],
  templateUrl: './voting-center.component.html',
  styleUrl: './voting-center.component.scss',
})
export class VotingCenterComponent implements OnInit {
  public segmentValue = 'room';
  public votingCenterRooms: VotingCenterClass[] = []; // * Array to store rooms
  public displayedColumns: string[] = ['name', 'actions'];
  public dataSource = new MatTableDataSource<VotingCenterClass>();
  public roomForm: FormGroup | undefined = undefined;
  public playerForm: FormGroup | undefined = undefined;

  constructor(
    private votingCenterService: VotingCenterService,
    private formBuilder: FormBuilder,
    private toastUtils: ToastUtils
  ) {}

  async ngOnInit(): Promise<void> {
    this.roomForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      banner_img: ['', [Validators.required]],
    });
    this.playerForm = this.formBuilder.group({
      player_name: ['', [Validators.required]],
      player_image: ['', [Validators.required]],
      room_id: ['', [Validators.required]],
    });
    await this.getRooms();
  }

  /**
   * * To get all rooms created
   */
  private async getRooms(): Promise<void> {
    this.votingCenterRooms = await this.votingCenterService.list((query) =>
      query.orderBy('create_at', 'desc').limit(15)
    );
    this.dataSource.data = this.votingCenterRooms;
  }

  /**
   * * Method to update a room
   * @param roomData as VotingCenterClass
   * @param toActive as boolean
   */
  public async updateRoom(
    roomData: VotingCenterClass,
    toActive: boolean
  ): Promise<void> {
    if (roomData) {
      if (toActive) {
        roomData.isActive = roomData.isActive ? false : true;
      } else {
        roomData.closeRoom = roomData.closeRoom ? false : true;
      }
      this.votingCenterService.update(roomData);
    }
  }

  /**
   * * Method to delete a room
   *
   * @param roomData as VotingCenterClass
   */
  public async deleteRoom(roomData: VotingCenterClass): Promise<void> {
    if (roomData) {
      await this.votingCenterService.delete(roomData.id);
      location.reload();
    }
  }

  /**
   * * Method to save a room
   */
  public async saveRoom(): Promise<void> {
    if (this.roomForm?.valid) {
      const roomObject: VotingCenterClass = {
        id: this.votingCenterService.createId(),
        name: this.roomForm.value['name'],
        banner_img: this.roomForm.value['banner_img'],
        closeRoom: true,
        isActive: false,
        create_at: new Date(),
      };
      await this.votingCenterService.set(roomObject);
      location.reload();
    }
  }

  /**
   * * Method to create a player
   */
  public async savePlayer(): Promise<void> {
    if (this.playerForm?.valid) {
      const playerObject: VotingCenterPlayerClass = {
        player_image: this.playerForm.value['player_image'],
        player_name: this.playerForm.value['player_name'],
        id: this.votingCenterService.createId(),
        created_at: new Date(),
        voters: [],
      };
      await this.votingCenterService
        .getRef(this.playerForm.value['room_id'])
        .collection('players')
        .add(playerObject)
        .then(() => {
          this.toastUtils.showToast('Participante creado.', 'success');
          this.playerForm?.reset();
        })
        .catch(() => {
          this.toastUtils.showToast(
            'Error al crear participante, intenta de nuevo.',
            'danger'
          );
        });
    }
  }
}
