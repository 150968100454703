<ion-card>
  @if (highlightsForm) {
  <form [formGroup]="highlightsForm">
    <ion-card-content>
      <p class="highlights-content__title">Crear Highlight Video</p>
      <ion-row>
        <ion-col size="6">
          <ion-input
            formControlName="id_jornada"
            label="ID Jornada"
            label-placement="floating"
            fill="outline"
            placeholder="ID de jornada. E.j: 12"
            type="number"
          ></ion-input>
        </ion-col>
        <ion-col size="6">
          <ion-input
            formControlName="match_name"
            label="Nombre del juego"
            label-placement="floating"
            fill="outline"
            placeholder="Ingrese el nombre de juego"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <ion-input
            formControlName="url_video"
            label="URL del video"
            label-placement="floating"
            fill="outline"
            placeholder="Pega la URL del video"
          ></ion-input>
        </ion-col>
        <ion-col size="6">
          <ion-input
            formControlName="banner_image"
            label="Banner image URL"
            label-placement="floating"
            fill="outline"
            placeholder="Ingrese la url para la imagen"
          ></ion-input>
        </ion-col>
      </ion-row>
      <div class="highlights-content__btn">
        <ion-button
          [disabled]="highlightsForm.invalid"
          (click)="createHighlight()"
          >Guardar video</ion-button
        >
      </div>
    </ion-card-content>
  </form>
  }
</ion-card>
<div class="jornadas-list p-3">
  <p class="highlights-content__title mt-4">Lista de highlights</p>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Nombre</th>
        <th scope="col">ID</th>
        <th scope="col">Status</th>
      </tr>
    </thead>
    <tbody>
      @if (highlightsList.length > 0) { @for (highlight of highlightsList; track
      $index) {
      <tr>
        <th scope="row">{{ $index + 1 }}</th>
        <td>{{ highlight.match_name }}</td>
        <td>{{ highlight.id_jornada }}</td>
        <td>
          <ion-button
            [ngClass]="{ isActive: highlight.status }"
            (click)="updateHighlightStatus(highlight)"
            >{{ highlight.status ? 'Inactivar' : 'Activar' }}</ion-button
          >
        </td>
      </tr>
      } }
    </tbody>
  </table>
</div>
