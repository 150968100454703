<div class="sidebar">
  <div class="sidebar-header">
    <p class="sidebar-header__title">LNFP - Admin Portal</p>
    <div class="d-flex justify-content-around align-items-center">
      <a href="https://lnphn-2096e.web.app" target="_blank"
        >Visitar anterior admin panel</a
      >
      <a>v1.0.6</a>
    </div>
  </div>
  <div class="sidebar-items">
    <ul class="sidebar-items__ul">
      @for (item of sidebarItems; track $index) {
      <li
        class="sidebar-items__li"
        (click)="itemSelected = item"
        [ngClass]="{ active: itemSelected === item }"
        [routerLink]="item.routePath"
      >
        <ion-icon [name]="item.iconPath"></ion-icon>
        {{ item.name }}
      </li>
      }
      <li class="sidebar-items__li" (click)="logOut()">
        <ion-icon name="log-out-outline"></ion-icon>
        Cerrar Sesión
      </li>
    </ul>
  </div>
</div>
