import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideIonicAngular } from '@ionic/angular/standalone';
import Honeybadger from '@honeybadger-io/js';
import { environment } from './environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

// Configure honeybadger.js
Honeybadger.configure({
  apiKey: environment.honeybadgerApiKey,
  environment: 'production',
});

// Define error handler component
class HoneybadgerErrorHandler implements ErrorHandler {
  handleError(error: { originalError: any }) {
    Honeybadger.notify(error.originalError || error);
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideIonicAngular(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    importProvidersFrom(
      AngularFireModule.initializeApp(environment.firebaseConfig),
      AngularFirestoreModule.enablePersistence({ synchronizeTabs: true })
    ), provideAnimationsAsync(),
    // { provide: ErrorHandler, useClass: HoneybadgerErrorHandler },
  ],
};
