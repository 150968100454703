<div class="highlights">
  <div class="highlights-header">
    <p>Highlights</p>
  </div>

  <ion-segment
    value="jornadas"
    mode="ios"
    class="highlights__ion-segment"
    [(ngModel)]="segmentValue"
  >
    <ion-segment-button value="jornadas">
      <ion-label>Crear Jornada</ion-label>
    </ion-segment-button>
    <ion-segment-button value="highlights">
      <ion-label>Crear Highlights</ion-label>
    </ion-segment-button>
  </ion-segment>

  <div class="highlights-content" [ngSwitch]="segmentValue">
    <div *ngSwitchCase="'jornadas'" class="mt-4">
      <ion-card>
        @if (jornadasForm) {
        <form [formGroup]="jornadasForm">
          <ion-card-content>
            <p class="highlights-content__title">Crear Jornada</p>
            <ion-row>
              <ion-col size="6">
                <ion-input
                  formControlName="id"
                  label="ID Jornada"
                  label-placement="floating"
                  fill="outline"
                  placeholder="ID de jornada. E.j: 12"
                  type="number"
                ></ion-input>
              </ion-col>
              <ion-col size="6">
                <ion-input
                  formControlName="name"
                  label="Nombre de Jornada"
                  label-placement="floating"
                  fill="outline"
                  placeholder="Ingrese el nombre de la jornada"
                ></ion-input>
              </ion-col>
            </ion-row>
            <div class="highlights-content__btn">
              <ion-button
                [disabled]="jornadasForm.invalid"
                (click)="createJornada()"
                >Guardar Jornada</ion-button
              >
            </div>
          </ion-card-content>
        </form>
        }
      </ion-card>
      <div class="jornadas-list p-3">
        <p class="highlights-content__title mt-4">Lista de jornadas</p>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">ID</th>
              <th scope="col">Nombre</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            @if (jornadasList.length > 0) { @for (jornada of jornadasList; track
            $index) {
            <tr>
              <th scope="row">{{ $index + 1 }}</th>
              <td>{{ jornada.id }}</td>
              <td>{{ jornada.name }}</td>
              <td>
                <ion-button
                  (click)="updateJornada(jornada)"
                  [ngClass]="{ isActive: jornada.isActive }"
                  >{{ jornada.isActive ? 'Inactivar' : 'Activar' }}</ion-button
                >
              </td>
            </tr>
            } }
          </tbody>
        </table>
      </div>
    </div>
    <div *ngSwitchCase="'highlights'" class="mt-4">
      <app-add-highlights></app-add-highlights>
    </div>
  </div>
</div>
