import { Route } from '@angular/router';
import { HighlightsComponent } from './components/highlights/highlights.component';
import { NewsComponent } from './components/news/news.component';
import { ReserveTournamentsComponent } from './components/reserve-tournaments/reserve-tournaments.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from '@lnfp/data-access';
import { UsersComponent } from './components/users/users.component';
import { SettingsComponent } from './components/settings/settings.component';
import { VotingCenterComponent } from './components/voting-center/voting-center.component';

export const appRoutes: Route[] = [
  { path: 'login', component: LoginComponent },
  {
    path: 'app',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'voting-center',
        component: VotingCenterComponent,
      },
      {
        path: 'highlights',
        component: HighlightsComponent,
      },
      {
        path: 'primicias',
        component: NewsComponent,
      },
      {
        path: 'torneo-reservas',
        component: ReserveTournamentsComponent,
      },
      {
        path: 'usuarios',
        component: UsersComponent,
      },
      {
        path: 'configuracion',
        component: SettingsComponent,
      },
    ],
  },
  { path: '', redirectTo: 'app/primicias', pathMatch: 'full' },
  { path: 'app', redirectTo: 'app/primicias', pathMatch: 'full' },
  { path: '**', redirectTo: 'login' },
];
