import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IonButton,
  IonInput,
  IonItem,
  ModalController,
} from '@ionic/angular/standalone';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ReservesTournamentService,
  ReservesTournamentTable,
} from '@lnfp/data-access';
import { ToastUtils } from '@lnfp/ui';

@Component({
  selector: 'app-add-team-modal',
  standalone: true,
  imports: [IonButton, IonItem, CommonModule, IonInput, ReactiveFormsModule],
  templateUrl: './add-team-modal.component.html',
  styleUrl: './add-team-modal.component.scss',
})
export class AddTeamModalComponent implements OnInit {
  @Input() currentTournamentId = '';
  public createTeamForm: FormGroup | undefined = undefined;

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private reservesTournamentService: ReservesTournamentService,
    private toastUtil: ToastUtils
  ) {}

  ngOnInit(): void {
    this.createTeamForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  /**
   * * Function to close modal
   *
   * @returns modalCtrl.dismiss
   */
  public cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  /**
   * * Method to add a team to a table tournament
   *
   */
  public async addTeamToTable(): Promise<void> {
    const idToUse = this.reservesTournamentService.createId();
    const tmpTeamData: ReservesTournamentTable = {
      team_name: this.createTeamForm?.get('name')?.value,
      uid: idToUse,
      id: idToUse,
      gc: 0,
      gf: 0,
      pe: 0,
      pg: 0,
      pp: 0,
      pj: 0,
      created_at: new Date(),
    };
    // * Adding team to current table
    this.reservesTournamentService
      .getRef(this.currentTournamentId)
      .collection('table')
      .doc(idToUse)
      .set(tmpTeamData)
      .then(async () => {
        this.toastUtil.showToast('Equipo añadido a la tabla', 'success');
        await this.modalCtrl.dismiss(null, 'confirm');
      })
      .catch((e) => {
        console.error('addTeamToTable()', e);
        this.toastUtil.showToast(
          'Hubo un error al añadir el equipo, intenta de nuevo',
          'danger'
        );
      });
  }
}
