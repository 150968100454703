import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DBCollectionName } from '../enums';
import { VotingCenterClass } from '../models';

@Injectable({ providedIn: 'root' })
export class VotingCenterService extends BaseService<VotingCenterClass> {
  constructor(angularFirestore: AngularFirestore) {
    super(DBCollectionName.VOTING_CENTER, angularFirestore);
  }
}
