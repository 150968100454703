import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

@Pipe({
  name: 'dateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  transform(value: Date, formatString: string): string {
    const date = value instanceof Timestamp ? value.toDate() : value;
    return format(date, formatString);
  }
}
