<div class="login-container">
  <h3>Iniciar Sesión</h3>
  @if (loginForm) {
  <form class="mt-3" [formGroup]="loginForm">
    <ion-item>
      <ion-input
        type="email"
        label="Correo Electrónico"
        labelPlacement="floating"
        placeholder="Ingresa tu correo"
        formControlName="email"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-input type="password" label="Contraseña" formControlName="password">
        <ion-input-password-toggle slot="end"></ion-input-password-toggle>
      </ion-input>
    </ion-item>
    <ion-button
      class="mt-4"
      shape="round"
      [disabled]="loginForm.invalid"
      (click)="login()"
      >Entrar</ion-button
    >
  </form>
  }
</div>
