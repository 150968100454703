import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { Jornadas } from "../models/jornadas.class";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { DBCollectionName } from "../enums";

@Injectable({ providedIn: 'root' })
export class JornadasService extends BaseService<Jornadas> {
  constructor(angularFirestore: AngularFirestore) {
    super(DBCollectionName.JORNADAS_HIGHLIGHTS, angularFirestore);
  }
}
